body .navbar-brand {
  padding: 5px 15px;
}

body .navbar-profile a {
  padding-top: 5px;
  padding-bottom: 0;
}

body .form-list-item {
  width: 200px;
}

body h1.page-header {
  margin-top: 20px;
}

.btn-inline {
  padding: 0;
  border: none;
  vertical-align: baseline;
}

.nowrap {
  white-space: nowrap;
}

.scrollable-menu {
  height: auto;
  max-height: 50vh;
  overflow-y: auto;
}

.show-client-product-mappings {
  height: 450px;
  overflow-y: auto;
}

.autosuggest {
  position: static;
  margin-right: 20px;
}

.autosuggest-suggestions {
  position: absolute;
}

.table-fixed-header table {
  table-layout: fixed;
  word-wrap: break-word;
  margin-bottom: 0;
}

.table-fixed-header .table-header {
  overflow-y: scroll;
  overflow-y: overlay;
}

.table-fixed-header .table-body {
  max-height: 450px;
  max-height: 55vh;
  overflow-y: scroll;
  overflow-y: overlay;
}

/*
  Like btn-xs, btn-sm, btn-lg, but "regular size" because default SASS decimal precision
  is not precise enough to calculate the height correctly and there does not seem to be a
  way to set the precision through bootstrap-sass-loader.
*/
.btn-rg {
  height: 34px;
}

.notification a {
  text-decoration: none;
}

.notification p {
  color: #777;
}

.notification:hover p {
  color: #333;
}

.alert-list.ReactTable .rt-tr .alert-panel-heading.rt-th {
  padding: 20px;
}

.read-button .unread-marker, .read-button:hover .read-marker {
  display: inline-block;
}

.read-button .read-marker, .read-button:hover .unread-marker {
  display: none;
}

.alert-list.ReactTable .rt-tr .rt-td {
  border: none;
}

.menu-item {
  border-top: 1px solid #eee;
  margin-top: 4px;
  padding-top: 4px;
}

.label-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem;
}

.radioLabel {
  margin: 0 25px 0 5px;
  font-weight: normal;
}

.react-select-inline-label {
  vertical-align: top;
  margin-top: 8px;
  margin-right: 5px;
}

.Select-mask {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

h3.page-header {
  margin-top: 20px;
}

.tab-content {
  margin-top: 20px;
}

.inlineTextInput {
  margin-top: 10%;
}

.datePicker {
  height: 34px;
  font-size: 14px;
  width: 100%;
}

.add-modal-container {
  width: 650px;
}

/*
  Because sometimes people tell you they want a modal, but then they put so much shit into the mockup that you need the
  entire screen to render it anyway.
*/
.beefy-modal {
  width: 95%;
}

th.sortable {
  cursor: pointer;
}

/* forms */
.form-horizontal .info {
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 7px;
}

.form-compressed .form-group {
  margin-bottom: 0;
}

.has-warning .Select-control {
  border-color: #8a6d3b;
}

.has-error .Select-control {
  border-color: #a94442;
}

.has-error label.ios7-switch:after {
  font-family: FontAwesome;
  font-size: 24px;
  line-height: 18px;
  color: red;
  content: '\f071';
}

.has-error input[type='checkbox']:after {
  position: absolute;
  right: 0;
  top: 0;
  font-family: FontAwesome;
  font-size: 14px;
  line-height: 18px;
  color: #a94442;
  content: '\f071';
}

p.form-control-static div.has-error input[type='checkbox']:after {
  position: absolute;
  right: 0;
  top: 9px;
  font-family: FontAwesome;
  font-size: 14px;
  line-height: 18px;
  color: #a94442;
  content: '\f071';
}

.has-error input[type='radio']:after {
  font-family: FontAwesome;
  font-size: 14px;
  color: #a94442;
  content: '\f06a';
}

.Select-menu-outer {
  z-index: 3 !important;
}

.activity {
  min-height: 75px;
  border-bottom: 1px solid #eee;
}

.tabs-bg-primary .nav-tabs {
  background-color: #337ab7;
}

.tabs-bg-primary .nav-tabs li:not(.active):not(:hover) a {
  color: #fff;
}

.minimal .Select-control {
  background-color: transparent !important;
}

.borderless .Select-control {
  border: 0;
  outline: 0;
  box-shadow: none;
}

/* react-virtualized table */
.ReactVirtualized__Table.rt-table {
  overflow-x: hidden;
}

.ReactVirtualized__Table__headerRow.rt-tr {
  text-transform: none;
}

.ReactVirtualized__Table__row.rt-tr.warning {
  background-color: #fcf8e3 !important;
}

.ReactVirtualized__Table__Grid.rt-tbody {
  display: block;
}

.client-product-properties .checkbox-inline {
  padding-top: 0;
}

.add-client-product-fields .checkbox-inline {
  padding-top: 0;
}

.dropdown-search {
  display: inline;
}

.dropdown-search ul {
  width: 350px;
}
.dropdown-search ul li {
  border-bottom: 1px solid #eee;
  cursor: pointer;
  padding: 10px;
}

.dropdown-search input {
  border: 0;
  border-bottom: 1px solid #ddd;
  color: #777;
  width: 350px;
}

.dropdown-search input:focus {
  box-shadow: unset;
  outline: 0;
}

.dropdown-search input::-webkit-input-placeholder {
  text-align: center;
}

.dropdown-search input:-moz-placeholder {
  /* Firefox 18- */
  text-align: center;
}

.dropdown-search input::-moz-placeholder {
  /* Firefox 19+ */
  text-align: center;
}

.dropdown-search input:-ms-input-placeholder {
  text-align: center;
}

.b {
  font-weight: bold;
}

.abbr {
  border-bottom: 1px dotted;
}

button.btn-feedback {
  color: #999;
  font-weight: normal;
  pointer-events: auto;
  z-index: 2;
}

button.btn-feedback:hover {
  text-decoration: none;
  color: #d0021b;
}

.form-horizontal .has-feedback .Select + .form-control-feedback {
  right: 35px;
}

.form-horizontal .has-right-input-group .form-control-feedback {
  right: 35px !important;
}

.split-row {
  width: 100%;
  display: table;
  table-layout: fixed;
}

.split-row .filters-col {
  border-right: 1px solid #e3e3e3;
  display: table-cell;
  float: none;
}

.split-row .actions-col {
  vertical-align: middle;
  display: table-cell;
  float: none;
}

.wide > .tooltip-inner {
  text-align: left;
  max-width: 800px;
}

.development-background {
  background-repeat: repeat;
  background-image: url('/static/development_background.png');
}

.staging-background {
  background-repeat: repeat;
  background-image: url('/static/staging_background.png');
}

.panel-title__icon {
  transition: transform linear 0.15s;
}

a[aria-expanded=true] .panel-title__icon {
  transform: rotate(90deg);
}
