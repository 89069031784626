/*
https://github.com/wilddeer/ios7-switch
*/
.ios7-switch {
    display: inline-block;
    position: relative;
    cursor: pointer;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-tap-highlight-color: transparent;
    tap-highlight-color: transparent;
}

.ios7-switch input {
    opacity: 0;
    position: absolute;
}

.ios7-switch input + span {
    position: relative;
    display: inline-block;
    width: 1.65em;
    height: 1em;
    background: #dadada;
    box-shadow: inset 0 0 0 0.0625em #e9e9e9;
    border-radius: 0.5em;
    vertical-align: -0.15em;
    transition: all 0.40s cubic-bezier(.17,.67,.43,.98);
}

.ios7-switch:active input + span,
.ios7-switch input + span:active {
    box-shadow: inset 0 0 0 0.73em #e9e9e9;
}

.ios7-switch input + span:after {
    position: absolute;
    display: block;
    content: '';
    width: 0.875em;
    height: 0.875em;
    border-radius: 0.4375em;
    top: 0.0625em;
    left: 0.0625em;
    background: white;
    box-shadow: inset 0 0 0 0.03em rgba(0,0,0,0.1),
                0 0 0.05em rgba(0,0,0,0.05),
                0 0.1em 0.2em rgba(0,0,0,0.2);
    transition: all 0.25s ease-out;
}

.ios7-switch:active input + span:after,
.ios7-switch input + span:active:after {
    width: 1.15em;
}

.ios7-switch input:checked + span {
    box-shadow: inset 0 0 0 0.73em #4cd964;
}

.ios7-switch input:checked + span:after {
    left: 0.7125em;
}

.ios7-switch:active input:checked + span:after,
.ios7-switch input:checked + span:active:after {
    left: 0.4375em;
}

/* accessibility styles */
.ios7-switch input:focus + span:after {
    box-shadow: inset 0 0 0 0.03em rgba(0,0,0,0.15),
                0 0 0.05em rgba(0,0,0,0.08),
                0 0.1em 0.2em rgba(0,0,0,0.3);
    background: #fff;
}

.ios7-switch input:focus + span {
    box-shadow: inset 0 0 0 0.0625em #dadada;
}

.ios7-switch input:focus:checked + span {
    box-shadow: inset 0 0 0 0.73em #33be4b;
}

/* reset accessibility style on hover */
.ios7-switch:hover input:focus + span:after {
    box-shadow: inset 0 0 0 0.03em rgba(0,0,0,0.1),
                0 0 0.05em rgba(0,0,0,0.05),
                0 0.1em 0.2em rgba(0,0,0,0.2);
    background: #fff;
}

.ios7-switch:hover input:focus + span {
    box-shadow: inset 0 0 0 0.0625em #e9e9e9;
}

.ios7-switch:hover input:focus:checked + span {
    box-shadow: inset 0 0 0 0.73em #4cd964;
}