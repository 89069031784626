.fdt-item-row .fixedDataTableCellLayout_main {
  background-color: #FFF; }

.fdt-alternate-row .fixedDataTableCellLayout_main {
  background-color: #f6f7f8; }

.autosuggest .autosuggest-suggestions ul {
  margin: 0;
  padding: 0;
  list-style-type: none; }

.autosuggest .suggestion {
  display: block;
  padding: 3px 10px; }

.autosuggest .suggestion:hover {
  background-color: #ddd; }

.ReactTable {
  position: relative;
  display: flex;
  flex-direction: column;
  border: 1px solid rgba(0, 0, 0, 0.1);
}
.ReactTable * {
  box-sizing: border-box;
}
.ReactTable .rt-table {
  flex: auto 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 100%;
  border-collapse: collapse;
  overflow: auto;
}
.ReactTable .rt-thead {
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.ReactTable .rt-thead.-headerGroups {
  background: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}
.ReactTable .rt-thead.-filters {
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

/*
.ReactTable .rt-thead.-filters input,.ReactTable .rt-thead.-filters select{
  border:1px solid rgba(0,0,0,0.1);background:#fff;padding:5px 7px;font-size:inherit;border-radius:3px;
  font-weight:normal;outline:none
}
*/

.ReactTable .rt-thead.-filters .rt-th {
  border-right: 1px solid rgba(0, 0, 0, 0.02);
}
.ReactTable .rt-thead.-header {
  box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.15);
}
.ReactTable .rt-thead:not(.-filters) .rt-tr {
  text-align: center;
}
.ReactTable .rt-thead .rt-th,
.ReactTable .rt-thead .rt-td {
  padding: 5px 5px;
  line-height: normal;
  position: relative;
  border-right: 1px solid rgba(0, 0, 0, 0.05);
  transition: box-shadow 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  box-shadow: inset 0 0 0 0 transparent;
}
.ReactTable .rt-thead .rt-th.-sort-asc,
.ReactTable .rt-thead .rt-td.-sort-asc {
  box-shadow: inset 0 3px 0 0 rgba(0, 0, 0, 0.6);
}
.ReactTable .rt-thead .rt-th.-sort-desc,
.ReactTable .rt-thead .rt-td.-sort-desc {
  box-shadow: inset 0 -3px 0 0 rgba(0, 0, 0, 0.6);
}
.ReactTable .rt-thead .rt-th.-cursor-pointer,
.ReactTable .rt-thead .rt-td.-cursor-pointer {
  cursor: pointer;
}
.ReactTable .rt-thead .rt-th:last-child,
.ReactTable .rt-thead .rt-td:last-child {
  border-right: 0;
}
.ReactTable .rt-thead .rt-resizable-header {
  overflow: visible;
}
.ReactTable .rt-thead .rt-resizable-header:last-child {
  overflow: hidden;
}
.ReactTable .rt-thead .rt-resizable-header-content {
  overflow: hidden;
  text-overflow: ellipsis;
}
.ReactTable .rt-thead .rt-header-pivot {
  border-right-color: #f7f7f7;
}
.ReactTable .rt-thead .rt-header-pivot:after,
.ReactTable .rt-thead .rt-header-pivot:before {
  left: 100%;
  top: 50%;
  border: solid transparent;
  content: ' ';
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}
.ReactTable .rt-thead .rt-header-pivot:after {
  border-color: rgba(255, 255, 255, 0);
  border-left-color: #fff;
  border-width: 8px;
  margin-top: -8px;
}
.ReactTable .rt-thead .rt-header-pivot:before {
  border-color: rgba(102, 102, 102, 0);
  border-left-color: #f7f7f7;
  border-width: 10px;
  margin-top: -10px;
}
.ReactTable .rt-tbody {
  flex: 99999 1 auto;
  display: flex;
  flex-direction: column;
  overflow: auto;
}
.ReactTable .rt-tbody .rt-tr-group {
  border-bottom: solid 1px rgba(0, 0, 0, 0.05);
}
.ReactTable .rt-tbody .rt-tr-group:last-child {
  border-bottom: 0;
}
.ReactTable .rt-tbody .rt-td {
  border-right: 1px solid rgba(0, 0, 0, 0.02);
}
.ReactTable .rt-tbody .rt-td:last-child {
  border-right: 0;
}
.ReactTable .rt-tbody .rt-expandable {
  cursor: pointer;
  text-overflow: clip;
}
.ReactTable .rt-tr-group {
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}
.ReactTable .rt-tr {
  flex: 1 0 auto;
  display: inline-flex;
}
.ReactTable .rt-th,
.ReactTable .rt-td {
  flex: 1 0;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding: 7px 5px;
  overflow: hidden;
  transition: 0.3s ease;
  transition-property: width, min-width, padding, opacity;
}
.ReactTable .rt-th.-hidden,
.ReactTable .rt-td.-hidden {
  width: 0 !important;
  min-width: 0 !important;
  padding: 0 !important;
  border: 0 !important;
  opacity: 0 !important;
}
.ReactTable .rt-expander {
  display: inline-block;
  position: relative;
  margin: 0;
  color: transparent;
  margin: 0 10px;
}
.ReactTable .rt-expander:after {
  content: '';
  position: absolute;
  width: 0;
  height: 0;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(-90deg);
  border-left: 5.04px solid transparent;
  border-right: 5.04px solid transparent;
  border-top: 7px solid rgba(0, 0, 0, 0.8);
  transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  cursor: pointer;
}
.ReactTable .rt-expander.-open:after {
  transform: translate(-50%, -50%) rotate(0);
}
.ReactTable .rt-resizer {
  display: inline-block;
  position: absolute;
  width: 36px;
  top: 0;
  bottom: 0;
  right: -18px;
  cursor: col-resize;
  z-index: 10;
}
.ReactTable .rt-tfoot {
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.15);
}
.ReactTable .rt-tfoot .rt-td {
  border-right: 1px solid rgba(0, 0, 0, 0.05);
}
.ReactTable .rt-tfoot .rt-td:last-child {
  border-right: 0;
}
.ReactTable.-striped .rt-tr.-odd {
  background: rgba(0, 0, 0, 0.03);
}
.ReactTable.-highlight .rt-tbody .rt-tr:not(.-padRow):hover {
  background: rgba(0, 0, 0, 0.05);
}
.ReactTable .-pagination {
  z-index: 1;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  flex-wrap: wrap;
  padding: 3px;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
  border-top: 2px solid rgba(0, 0, 0, 0.1);
}

.ReactTable .-pagination input,
.ReactTable .-pagination select {
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: #fff;
  padding: 5px 7px;
  font-size: inherit;
  border-radius: 3px;
  font-weight: normal;
  outline: none;
}

.ReactTable .-pagination .-btn {
  -webkit-appearance: none;
  appearance: none;
  display: block;
  width: 100%;
  height: 100%;
  border: 0;
  border-radius: 3px;
  padding: 6px;
  font-size: 1em;
  color: rgba(0, 0, 0, 0.6);
  background: rgba(0, 0, 0, 0.1);
  transition: all 0.1s ease;
  cursor: pointer;
  outline: none;
}
.ReactTable .-pagination .-btn[disabled] {
  opacity: 0.5;
  cursor: default;
}
.ReactTable .-pagination .-btn:not([disabled]):hover {
  background: rgba(0, 0, 0, 0.3);
  color: #fff;
}
.ReactTable .-pagination .-previous,
.ReactTable .-pagination .-next {
  flex: 1 1;
  text-align: center;
}
.ReactTable .-pagination .-center {
  flex: 1.5 1;
  text-align: center;
  margin-bottom: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;
}
.ReactTable .-pagination .-pageInfo {
  display: inline-block;
  margin: 3px 10px;
  white-space: nowrap;
}
.ReactTable .-pagination .-pageJump {
  display: inline-block;
}
.ReactTable .-pagination .-pageJump input {
  width: 70px;
  text-align: center;
}
.ReactTable .-pagination .-pageSizeOptions {
  margin: 3px 10px;
}
.ReactTable .rt-noData {
  display: block;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: rgba(255, 255, 255, 0.8);
  transition: all 0.3s ease;
  z-index: 1;
  pointer-events: none;
  padding: 20px;
  color: rgba(0, 0, 0, 0.5);
}
.ReactTable .-loading {
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.8);
  transition: all 0.3s ease;
  z-index: -1;
  opacity: 0;
  pointer-events: none;
}
.ReactTable .-loading > div {
  position: absolute;
  display: block;
  text-align: center;
  width: 100%;
  top: 50%;
  left: 0;
  font-size: 15px;
  color: rgba(0, 0, 0, 0.6);
  transform: translateY(-52%);
  transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
.ReactTable .-loading.-active {
  opacity: 1;
  z-index: 2;
  pointer-events: all;
}
.ReactTable .-loading.-active > div {
  transform: translateY(50%);
}
.ReactTable .rt-resizing .rt-th,
.ReactTable .rt-resizing .rt-td {
  transition: none !important;
  cursor: col-resize;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/*
https://github.com/wilddeer/ios7-switch
*/
.ios7-switch {
    display: inline-block;
    position: relative;
    cursor: pointer;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-tap-highlight-color: transparent;
    tap-highlight-color: transparent;
}

.ios7-switch input {
    opacity: 0;
    position: absolute;
}

.ios7-switch input + span {
    position: relative;
    display: inline-block;
    width: 1.65em;
    height: 1em;
    background: #dadada;
    box-shadow: inset 0 0 0 0.0625em #e9e9e9;
    border-radius: 0.5em;
    vertical-align: -0.15em;
    transition: all 0.40s cubic-bezier(.17,.67,.43,.98);
}

.ios7-switch:active input + span,
.ios7-switch input + span:active {
    box-shadow: inset 0 0 0 0.73em #e9e9e9;
}

.ios7-switch input + span:after {
    position: absolute;
    display: block;
    content: '';
    width: 0.875em;
    height: 0.875em;
    border-radius: 0.4375em;
    top: 0.0625em;
    left: 0.0625em;
    background: white;
    box-shadow: inset 0 0 0 0.03em rgba(0,0,0,0.1),
                0 0 0.05em rgba(0,0,0,0.05),
                0 0.1em 0.2em rgba(0,0,0,0.2);
    transition: all 0.25s ease-out;
}

.ios7-switch:active input + span:after,
.ios7-switch input + span:active:after {
    width: 1.15em;
}

.ios7-switch input:checked + span {
    box-shadow: inset 0 0 0 0.73em #4cd964;
}

.ios7-switch input:checked + span:after {
    left: 0.7125em;
}

.ios7-switch:active input:checked + span:after,
.ios7-switch input:checked + span:active:after {
    left: 0.4375em;
}

/* accessibility styles */
.ios7-switch input:focus + span:after {
    box-shadow: inset 0 0 0 0.03em rgba(0,0,0,0.15),
                0 0 0.05em rgba(0,0,0,0.08),
                0 0.1em 0.2em rgba(0,0,0,0.3);
    background: #fff;
}

.ios7-switch input:focus + span {
    box-shadow: inset 0 0 0 0.0625em #dadada;
}

.ios7-switch input:focus:checked + span {
    box-shadow: inset 0 0 0 0.73em #33be4b;
}

/* reset accessibility style on hover */
.ios7-switch:hover input:focus + span:after {
    box-shadow: inset 0 0 0 0.03em rgba(0,0,0,0.1),
                0 0 0.05em rgba(0,0,0,0.05),
                0 0.1em 0.2em rgba(0,0,0,0.2);
    background: #fff;
}

.ios7-switch:hover input:focus + span {
    box-shadow: inset 0 0 0 0.0625em #e9e9e9;
}

.ios7-switch:hover input:focus:checked + span {
    box-shadow: inset 0 0 0 0.73em #4cd964;
}
/* Don't include base for now because it affects header tags and others which we use in old code */

/* See https://tailwindcss.com/docs/preflight */

/* @tailwind base; */

.container {
  width: 100%
}

@media (min-width: 640px) {
  .container {
    max-width: 640px
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 768px
  }
}

@media (min-width: 1024px) {
  .container {
    max-width: 1024px
  }
}

@media (min-width: 1280px) {
  .container {
    max-width: 1280px
  }
}

.space-x-2 > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(0.5rem * var(--space-x-reverse));
  margin-left: calc(0.5rem * calc(1 - var(--space-x-reverse)))
}

.space-x-4 > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(1rem * var(--space-x-reverse));
  margin-left: calc(1rem * calc(1 - var(--space-x-reverse)))
}

.space-x-10 > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(2.5rem * var(--space-x-reverse));
  margin-left: calc(2.5rem * calc(1 - var(--space-x-reverse)))
}

.bg-transparent {
  background-color: transparent
}

.bg-white {
  --bg-opacity: 1;
  background-color: #fff;
  background-color: rgba(255, 255, 255, var(--bg-opacity))
}

.bg-gray-100 {
  --bg-opacity: 1;
  background-color: #f7fafc;
  background-color: rgba(247, 250, 252, var(--bg-opacity))
}

.bg-gray-300 {
  --bg-opacity: 1;
  background-color: #e2e8f0;
  background-color: rgba(226, 232, 240, var(--bg-opacity))
}

.bg-red-200 {
  --bg-opacity: 1;
  background-color: #fed7d7;
  background-color: rgba(254, 215, 215, var(--bg-opacity))
}

.bg-orange-300 {
  --bg-opacity: 1;
  background-color: #fbd38d;
  background-color: rgba(251, 211, 141, var(--bg-opacity))
}

.bg-yellow-200 {
  --bg-opacity: 1;
  background-color: #fefcbf;
  background-color: rgba(254, 252, 191, var(--bg-opacity))
}

.bg-blue-100 {
  --bg-opacity: 1;
  background-color: #ebf8ff;
  background-color: rgba(235, 248, 255, var(--bg-opacity))
}

.bg-blue-200 {
  --bg-opacity: 1;
  background-color: #bee3f8;
  background-color: rgba(190, 227, 248, var(--bg-opacity))
}

.bg-blue-600 {
  --bg-opacity: 1;
  background-color: #3182ce;
  background-color: rgba(49, 130, 206, var(--bg-opacity))
}

.bg-blue-700 {
  --bg-opacity: 1;
  background-color: #2b6cb0;
  background-color: rgba(43, 108, 176, var(--bg-opacity))
}

.hover\:bg-gray-200:hover {
  --bg-opacity: 1;
  background-color: #edf2f7;
  background-color: rgba(237, 242, 247, var(--bg-opacity))
}

.hover\:bg-gray-400:hover {
  --bg-opacity: 1;
  background-color: #cbd5e0;
  background-color: rgba(203, 213, 224, var(--bg-opacity))
}

.hover\:bg-red-300:hover {
  --bg-opacity: 1;
  background-color: #feb2b2;
  background-color: rgba(254, 178, 178, var(--bg-opacity))
}

.hover\:bg-yellow-300:hover {
  --bg-opacity: 1;
  background-color: #faf089;
  background-color: rgba(250, 240, 137, var(--bg-opacity))
}

.hover\:bg-blue-200:hover {
  --bg-opacity: 1;
  background-color: #bee3f8;
  background-color: rgba(190, 227, 248, var(--bg-opacity))
}

.hover\:bg-blue-300:hover {
  --bg-opacity: 1;
  background-color: #90cdf4;
  background-color: rgba(144, 205, 244, var(--bg-opacity))
}

.odd\:bg-gray-100:nth-child(odd) {
  --bg-opacity: 1;
  background-color: #f7fafc;
  background-color: rgba(247, 250, 252, var(--bg-opacity))
}

.hover\:bg-opacity-75:hover {
  --bg-opacity: 0.75
}

.border-gray-400 {
  --border-opacity: 1;
  border-color: #cbd5e0;
  border-color: rgba(203, 213, 224, var(--border-opacity))
}

.border-red-800 {
  --border-opacity: 1;
  border-color: #9b2c2c;
  border-color: rgba(155, 44, 44, var(--border-opacity))
}

.border-blue-500 {
  --border-opacity: 1;
  border-color: #4299e1;
  border-color: rgba(66, 153, 225, var(--border-opacity))
}

.border-blue-600 {
  --border-opacity: 1;
  border-color: #3182ce;
  border-color: rgba(49, 130, 206, var(--border-opacity))
}

.hover\:border-blue-600:hover {
  --border-opacity: 1;
  border-color: #3182ce;
  border-color: rgba(49, 130, 206, var(--border-opacity))
}

.rounded {
  border-radius: 0.25rem
}

.rounded-md {
  border-radius: 0.375rem
}

.rounded-t-lg {
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem
}

.border-solid {
  border-style: solid
}

.border-none {
  border-style: none
}

.border-0 {
  border-width: 0
}

.border {
  border-width: 1px
}

.border-t-2 {
  border-top-width: 2px
}

.border-r-2 {
  border-right-width: 2px
}

.border-b-2 {
  border-bottom-width: 2px
}

.border-l-4 {
  border-left-width: 4px
}

.cursor-pointer {
  cursor: pointer
}

.cursor-not-allowed {
  cursor: not-allowed
}

.block {
  display: block
}

.inline-block {
  display: inline-block
}

.inline {
  display: inline
}

.flex {
  display: flex
}

.table {
  display: table
}

.grid {
  display: grid
}

.contents {
  display: contents
}

.hidden {
  display: none
}

.flex-col {
  flex-direction: column
}

.flex-wrap {
  flex-wrap: wrap
}

.place-items-center {
  place-items: center
}

.items-center {
  align-items: center
}

.content-center {
  align-content: center
}

.self-center {
  align-self: center
}

.justify-start {
  justify-content: flex-start
}

.justify-end {
  justify-content: flex-end
}

.justify-center {
  justify-content: center
}

.justify-between {
  justify-content: space-between
}

.flex-1 {
  flex: 1 1
}

.float-right {
  float: right
}

.clearfix:after {
  content: "";
  display: table;
  clear: both
}

.clear-left {
  clear: left
}

.clear-right {
  clear: right
}

.font-normal {
  font-weight: 400
}

.font-semibold {
  font-weight: 600
}

.font-bold {
  font-weight: 700
}

.h-6 {
  height: 1.5rem
}

.h-16 {
  height: 4rem
}

.h-20 {
  height: 5rem
}

.h-32 {
  height: 8rem
}

.h-48 {
  height: 12rem
}

.h-full {
  height: 100%
}

.text-sm {
  font-size: 12px;
  line-height: 20px
}

.text-lg {
  font-size: 18px;
  line-height: 28px
}

.list-none {
  list-style-type: none
}

.list-disc {
  list-style-type: disc
}

.m-0 {
  margin: 0
}

.m-12 {
  margin: 3rem
}

.mx-2 {
  margin-left: 0.5rem;
  margin-right: 0.5rem
}

.mt-0 {
  margin-top: 0
}

.mb-0 {
  margin-bottom: 0
}

.mt-2 {
  margin-top: 0.5rem
}

.mr-2 {
  margin-right: 0.5rem
}

.ml-2 {
  margin-left: 0.5rem
}

.mt-3 {
  margin-top: 0.75rem
}

.mr-3 {
  margin-right: 0.75rem
}

.mt-4 {
  margin-top: 1rem
}

.mr-4 {
  margin-right: 1rem
}

.mb-4 {
  margin-bottom: 1rem
}

.ml-4 {
  margin-left: 1rem
}

.mt-5 {
  margin-top: 1.25rem
}

.mr-5 {
  margin-right: 1.25rem
}

.ml-6 {
  margin-left: 1.5rem
}

.mt-8 {
  margin-top: 2rem
}

.mb-8 {
  margin-bottom: 2rem
}

.ml-8 {
  margin-left: 2rem
}

.mt-10 {
  margin-top: 2.5rem
}

.mb-10 {
  margin-bottom: 2.5rem
}

.mb-12 {
  margin-bottom: 3rem
}

.mt-16 {
  margin-top: 4rem
}

.mb-16 {
  margin-bottom: 4rem
}

.mt-20 {
  margin-top: 5rem
}

.mr-20 {
  margin-right: 5rem
}

.mb-20 {
  margin-bottom: 5rem
}

.mt-24 {
  margin-top: 6rem
}

.ml-24 {
  margin-left: 6rem
}

.mt-56 {
  margin-top: 14rem
}

.mt-auto {
  margin-top: auto
}

.-mb-2 {
  margin-bottom: -0.5rem
}

.-mb-4 {
  margin-bottom: -1rem
}

.max-w-6xl {
  max-width: 72rem
}

.max-w-full {
  max-width: 100%
}

.opacity-0 {
  opacity: 0
}

.opacity-50 {
  opacity: 0.5
}

.opacity-100 {
  opacity: 1
}

.overflow-auto {
  overflow: auto
}

.p-0 {
  padding: 0
}

.p-1 {
  padding: 0.25rem
}

.p-2 {
  padding: 0.5rem
}

.p-3 {
  padding: 0.75rem
}

.p-4 {
  padding: 1rem
}

.p-6 {
  padding: 1.5rem
}

.py-2 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem
}

.py-3 {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem
}

.py-6 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem
}

.py-10 {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem
}

.px-20 {
  padding-left: 5rem;
  padding-right: 5rem
}

.px-24 {
  padding-left: 6rem;
  padding-right: 6rem
}

.pt-0 {
  padding-top: 0
}

.pr-0 {
  padding-right: 0
}

.pb-0 {
  padding-bottom: 0
}

.pl-0 {
  padding-left: 0
}

.pl-1 {
  padding-left: 0.25rem
}

.pt-2 {
  padding-top: 0.5rem
}

.pr-2 {
  padding-right: 0.5rem
}

.pl-2 {
  padding-left: 0.5rem
}

.pl-3 {
  padding-left: 0.75rem
}

.pt-4 {
  padding-top: 1rem
}

.pb-4 {
  padding-bottom: 1rem
}

.pt-5 {
  padding-top: 1.25rem
}

.pr-5 {
  padding-right: 1.25rem
}

.pb-5 {
  padding-bottom: 1.25rem
}

.pl-5 {
  padding-left: 1.25rem
}

.pt-6 {
  padding-top: 1.5rem
}

.pr-6 {
  padding-right: 1.5rem
}

.pb-6 {
  padding-bottom: 1.5rem
}

.pl-6 {
  padding-left: 1.5rem
}

.pt-8 {
  padding-top: 2rem
}

.pr-8 {
  padding-right: 2rem
}

.pl-8 {
  padding-left: 2rem
}

.pr-10 {
  padding-right: 2.5rem
}

.pb-10 {
  padding-bottom: 2.5rem
}

.pt-12 {
  padding-top: 3rem
}

.pr-12 {
  padding-right: 3rem
}

.pl-12 {
  padding-left: 3rem
}

.pr-16 {
  padding-right: 4rem
}

.pb-16 {
  padding-bottom: 4rem
}

.pl-16 {
  padding-left: 4rem
}

.pr-20 {
  padding-right: 5rem
}

.pointer-events-none {
  pointer-events: none
}

.pointer-events-auto {
  pointer-events: auto
}

.static {
  position: static
}

.fixed {
  position: fixed
}

.absolute {
  position: absolute
}

.relative {
  position: relative
}

.sticky {
  position: sticky
}

.top-0 {
  top: 0
}

.right-0 {
  right: 0
}

.left-0 {
  left: 0
}

.resize-none {
  resize: none
}

.resize {
  resize: both
}

.shadow {
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)
}

.shadow-lg {
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)
}

.table-auto {
  table-layout: auto
}

.text-left {
  text-align: left
}

.text-center {
  text-align: center
}

.text-right {
  text-align: right
}

.text-justify {
  text-align: justify
}

.text-black {
  --text-opacity: 1;
  color: #000;
  color: rgba(0, 0, 0, var(--text-opacity))
}

.text-white {
  --text-opacity: 1;
  color: #fff;
  color: rgba(255, 255, 255, var(--text-opacity))
}

.text-gray-500 {
  --text-opacity: 1;
  color: #a0aec0;
  color: rgba(160, 174, 192, var(--text-opacity))
}

.text-gray-600 {
  --text-opacity: 1;
  color: #718096;
  color: rgba(113, 128, 150, var(--text-opacity))
}

.text-gray-700 {
  --text-opacity: 1;
  color: #4a5568;
  color: rgba(74, 85, 104, var(--text-opacity))
}

.text-gray-800 {
  --text-opacity: 1;
  color: #2d3748;
  color: rgba(45, 55, 72, var(--text-opacity))
}

.text-red-500 {
  --text-opacity: 1;
  color: #f56565;
  color: rgba(245, 101, 101, var(--text-opacity))
}

.text-red-600 {
  --text-opacity: 1;
  color: #e53e3e;
  color: rgba(229, 62, 62, var(--text-opacity))
}

.text-red-700 {
  --text-opacity: 1;
  color: #c53030;
  color: rgba(197, 48, 48, var(--text-opacity))
}

.text-orange-500 {
  --text-opacity: 1;
  color: #ed8936;
  color: rgba(237, 137, 54, var(--text-opacity))
}

.text-orange-700 {
  --text-opacity: 1;
  color: #c05621;
  color: rgba(192, 86, 33, var(--text-opacity))
}

.text-green-500 {
  --text-opacity: 1;
  color: #48bb78;
  color: rgba(72, 187, 120, var(--text-opacity))
}

.text-green-600 {
  --text-opacity: 1;
  color: #38a169;
  color: rgba(56, 161, 105, var(--text-opacity))
}

.text-green-700 {
  --text-opacity: 1;
  color: #2f855a;
  color: rgba(47, 133, 90, var(--text-opacity))
}

.text-blue-500 {
  --text-opacity: 1;
  color: #4299e1;
  color: rgba(66, 153, 225, var(--text-opacity))
}

.text-blue-600 {
  --text-opacity: 1;
  color: #3182ce;
  color: rgba(49, 130, 206, var(--text-opacity))
}

.text-blue-700 {
  --text-opacity: 1;
  color: #2b6cb0;
  color: rgba(43, 108, 176, var(--text-opacity))
}

.hover\:text-white:hover {
  --text-opacity: 1;
  color: #fff;
  color: rgba(255, 255, 255, var(--text-opacity))
}

.hover\:text-gray-700:hover {
  --text-opacity: 1;
  color: #4a5568;
  color: rgba(74, 85, 104, var(--text-opacity))
}

.italic {
  font-style: italic
}

.uppercase {
  text-transform: uppercase
}

.lowercase {
  text-transform: lowercase
}

.capitalize {
  text-transform: capitalize
}

.underline {
  text-decoration: underline
}

.hover\:underline:hover {
  text-decoration: underline
}

.antialiased {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale
}

.align-top {
  vertical-align: top
}

.align-bottom {
  vertical-align: bottom
}

.visible {
  visibility: visible
}

.invisible {
  visibility: hidden
}

.whitespace-pre-line {
  white-space: pre-line
}

.whitespace-pre-wrap {
  white-space: pre-wrap
}

.break-all {
  word-break: break-all
}

.truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap
}

.w-8 {
  width: 2rem
}

.w-16 {
  width: 4rem
}

.w-1\/2 {
  width: 50%
}

.w-1\/3 {
  width: 33.333333%
}

.w-1\/5 {
  width: 20%
}

.w-3\/12 {
  width: 25%
}

.w-full {
  width: 100%
}

.z-40 {
  z-index: 40
}

.gap-2 {
  grid-gap: 0.5rem;
  grid-gap: 0.5rem;
  gap: 0.5rem
}

.gap-4 {
  grid-gap: 1rem;
  grid-gap: 1rem;
  gap: 1rem
}

.gap-8 {
  grid-gap: 2rem;
  grid-gap: 2rem;
  gap: 2rem
}

.gap-x-4 {
  grid-column-gap: 1rem;
  grid-column-gap: 1rem;
  column-gap: 1rem
}

.gap-x-12 {
  grid-column-gap: 3rem;
  grid-column-gap: 3rem;
  column-gap: 3rem
}

.gap-y-4 {
  grid-row-gap: 1rem;
  grid-row-gap: 1rem;
  row-gap: 1rem
}

.grid-flow-row {
  grid-auto-flow: row
}

.grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr))
}

.grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr))
}

.grid-cols-3 {
  grid-template-columns: repeat(3, minmax(0, 1fr))
}

.grid-cols-4 {
  grid-template-columns: repeat(4, minmax(0, 1fr))
}

.grid-cols-6 {
  grid-template-columns: repeat(6, minmax(0, 1fr))
}

.grid-cols-10 {
  grid-template-columns: repeat(10, minmax(0, 1fr))
}

.grid-cols-12 {
  grid-template-columns: repeat(12, minmax(0, 1fr))
}

.col-span-1 {
  grid-column: span 1 / span 1
}

.col-span-2 {
  grid-column: span 2 / span 2
}

.col-span-3 {
  grid-column: span 3 / span 3
}

.col-span-4 {
  grid-column: span 4 / span 4
}

.col-span-8 {
  grid-column: span 8 / span 8
}

.col-span-9 {
  grid-column: span 9 / span 9
}

.col-start-1 {
  grid-column-start: 1
}

.col-start-2 {
  grid-column-start: 2
}

.col-start-3 {
  grid-column-start: 3
}

.col-start-11 {
  grid-column-start: 11
}

.col-start-12 {
  grid-column-start: 12
}

.grid-rows-2 {
  grid-template-rows: repeat(2, minmax(0, 1fr))
}

.row-span-2 {
  grid-row: span 2 / span 2
}

.transform {
  --transform-translate-x: 0;
  --transform-translate-y: 0;
  --transform-rotate: 0;
  --transform-skew-x: 0;
  --transform-skew-y: 0;
  --transform-scale-x: 1;
  --transform-scale-y: 1;
  transform: translateX(var(--transform-translate-x)) translateY(var(--transform-translate-y)) rotate(var(--transform-rotate)) skewX(var(--transform-skew-x)) skewY(var(--transform-skew-y)) scaleX(var(--transform-scale-x)) scaleY(var(--transform-scale-y))
}

.scale-95 {
  --transform-scale-x: .95;
  --transform-scale-y: .95
}

.scale-100 {
  --transform-scale-x: 1;
  --transform-scale-y: 1
}

.transition {
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform
}

.ease-in {
  transition-timing-function: cubic-bezier(0.4, 0, 1, 1)
}

.ease-out {
  transition-timing-function: cubic-bezier(0, 0, 0.2, 1)
}

.duration-75 {
  transition-duration: 75ms
}

.duration-100 {
  transition-duration: 100ms
}

@keyframes spin {
  to {
    transform: rotate(360deg)
  }
}

@keyframes ping {
  75%, 100% {
    transform: scale(2);
    opacity: 0
  }
}

@keyframes pulse {
  50% {
    opacity: .5
  }
}

@keyframes bounce {
  0%, 100% {
    transform: translateY(-25%);
    animation-timing-function: cubic-bezier(0.8,0,1,1)
  }

  50% {
    transform: none;
    animation-timing-function: cubic-bezier(0,0,0.2,1)
  }
}

@media (min-width: 640px) {
}

@media (min-width: 768px) {
  .md\:flex-row {
    flex-direction: row
  }

  .md\:mb-0 {
    margin-bottom: 0
  }

  .md\:w-32 {
    width: 8rem
  }

  .md\:w-64 {
    width: 16rem
  }
}

@media (min-width: 1024px) {
  .lg\:-my-4 {
    margin-top: -1rem;
    margin-bottom: -1rem
  }

  .lg\:mb-0 {
    margin-bottom: 0
  }

  .lg\:mb-4 {
    margin-bottom: 1rem
  }

  .lg\:grid-cols-6 {
    grid-template-columns: repeat(6, minmax(0, 1fr))
  }

  .lg\:grid-cols-10 {
    grid-template-columns: repeat(10, minmax(0, 1fr))
  }

  .lg\:col-span-2 {
    grid-column: span 2 / span 2
  }

  .lg\:col-span-5 {
    grid-column: span 5 / span 5
  }

  .lg\:col-span-8 {
    grid-column: span 8 / span 8
  }
}

@media (min-width: 1280px) {
  .xl\:py-8 {
    padding-top: 2rem;
    padding-bottom: 2rem
  }

  .xl\:px-20 {
    padding-left: 5rem;
    padding-right: 5rem
  }

  .xl\:gap-x-32 {
    grid-column-gap: 8rem;
    grid-column-gap: 8rem;
    column-gap: 8rem
  }
}
body .navbar-brand {
  padding: 5px 15px;
}

body .navbar-profile a {
  padding-top: 5px;
  padding-bottom: 0;
}

body .form-list-item {
  width: 200px;
}

body h1.page-header {
  margin-top: 20px;
}

.btn-inline {
  padding: 0;
  border: none;
  vertical-align: baseline;
}

.nowrap {
  white-space: nowrap;
}

.scrollable-menu {
  height: auto;
  max-height: 50vh;
  overflow-y: auto;
}

.show-client-product-mappings {
  height: 450px;
  overflow-y: auto;
}

.autosuggest {
  position: static;
  margin-right: 20px;
}

.autosuggest-suggestions {
  position: absolute;
}

.table-fixed-header table {
  table-layout: fixed;
  word-wrap: break-word;
  margin-bottom: 0;
}

.table-fixed-header .table-header {
  overflow-y: scroll;
  overflow-y: overlay;
}

.table-fixed-header .table-body {
  max-height: 450px;
  max-height: 55vh;
  overflow-y: scroll;
  overflow-y: overlay;
}

/*
  Like btn-xs, btn-sm, btn-lg, but "regular size" because default SASS decimal precision
  is not precise enough to calculate the height correctly and there does not seem to be a
  way to set the precision through bootstrap-sass-loader.
*/
.btn-rg {
  height: 34px;
}

.notification a {
  text-decoration: none;
}

.notification p {
  color: #777;
}

.notification:hover p {
  color: #333;
}

.alert-list.ReactTable .rt-tr .alert-panel-heading.rt-th {
  padding: 20px;
}

.read-button .unread-marker, .read-button:hover .read-marker {
  display: inline-block;
}

.read-button .read-marker, .read-button:hover .unread-marker {
  display: none;
}

.alert-list.ReactTable .rt-tr .rt-td {
  border: none;
}

.menu-item {
  border-top: 1px solid #eee;
  margin-top: 4px;
  padding-top: 4px;
}

.label-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem;
}

.radioLabel {
  margin: 0 25px 0 5px;
  font-weight: normal;
}

.react-select-inline-label {
  vertical-align: top;
  margin-top: 8px;
  margin-right: 5px;
}

.Select-mask {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

h3.page-header {
  margin-top: 20px;
}

.tab-content {
  margin-top: 20px;
}

.inlineTextInput {
  margin-top: 10%;
}

.datePicker {
  height: 34px;
  font-size: 14px;
  width: 100%;
}

.add-modal-container {
  width: 650px;
}

/*
  Because sometimes people tell you they want a modal, but then they put so much shit into the mockup that you need the
  entire screen to render it anyway.
*/
.beefy-modal {
  width: 95%;
}

th.sortable {
  cursor: pointer;
}

/* forms */
.form-horizontal .info {
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 7px;
}

.form-compressed .form-group {
  margin-bottom: 0;
}

.has-warning .Select-control {
  border-color: #8a6d3b;
}

.has-error .Select-control {
  border-color: #a94442;
}

.has-error label.ios7-switch:after {
  font-family: FontAwesome;
  font-size: 24px;
  line-height: 18px;
  color: red;
  content: '\f071';
}

.has-error input[type='checkbox']:after {
  position: absolute;
  right: 0;
  top: 0;
  font-family: FontAwesome;
  font-size: 14px;
  line-height: 18px;
  color: #a94442;
  content: '\f071';
}

p.form-control-static div.has-error input[type='checkbox']:after {
  position: absolute;
  right: 0;
  top: 9px;
  font-family: FontAwesome;
  font-size: 14px;
  line-height: 18px;
  color: #a94442;
  content: '\f071';
}

.has-error input[type='radio']:after {
  font-family: FontAwesome;
  font-size: 14px;
  color: #a94442;
  content: '\f06a';
}

.Select-menu-outer {
  z-index: 3 !important;
}

.activity {
  min-height: 75px;
  border-bottom: 1px solid #eee;
}

.tabs-bg-primary .nav-tabs {
  background-color: #337ab7;
}

.tabs-bg-primary .nav-tabs li:not(.active):not(:hover) a {
  color: #fff;
}

.minimal .Select-control {
  background-color: transparent !important;
}

.borderless .Select-control {
  border: 0;
  outline: 0;
  box-shadow: none;
}

/* react-virtualized table */
.ReactVirtualized__Table.rt-table {
  overflow-x: hidden;
}

.ReactVirtualized__Table__headerRow.rt-tr {
  text-transform: none;
}

.ReactVirtualized__Table__row.rt-tr.warning {
  background-color: #fcf8e3 !important;
}

.ReactVirtualized__Table__Grid.rt-tbody {
  display: block;
}

.client-product-properties .checkbox-inline {
  padding-top: 0;
}

.add-client-product-fields .checkbox-inline {
  padding-top: 0;
}

.dropdown-search {
  display: inline;
}

.dropdown-search ul {
  width: 350px;
}
.dropdown-search ul li {
  border-bottom: 1px solid #eee;
  cursor: pointer;
  padding: 10px;
}

.dropdown-search input {
  border: 0;
  border-bottom: 1px solid #ddd;
  color: #777;
  width: 350px;
}

.dropdown-search input:focus {
  box-shadow: unset;
  outline: 0;
}

.dropdown-search input::-webkit-input-placeholder {
  text-align: center;
}

.dropdown-search input:-moz-placeholder {
  /* Firefox 18- */
  text-align: center;
}

.dropdown-search input::-moz-placeholder {
  /* Firefox 19+ */
  text-align: center;
}

.dropdown-search input:-ms-input-placeholder {
  text-align: center;
}

.b {
  font-weight: bold;
}

.abbr {
  border-bottom: 1px dotted;
}

button.btn-feedback {
  color: #999;
  font-weight: normal;
  pointer-events: auto;
  z-index: 2;
}

button.btn-feedback:hover {
  text-decoration: none;
  color: #d0021b;
}

.form-horizontal .has-feedback .Select + .form-control-feedback {
  right: 35px;
}

.form-horizontal .has-right-input-group .form-control-feedback {
  right: 35px !important;
}

.split-row {
  width: 100%;
  display: table;
  table-layout: fixed;
}

.split-row .filters-col {
  border-right: 1px solid #e3e3e3;
  display: table-cell;
  float: none;
}

.split-row .actions-col {
  vertical-align: middle;
  display: table-cell;
  float: none;
}

.wide > .tooltip-inner {
  text-align: left;
  max-width: 800px;
}

.development-background {
  background-repeat: repeat;
  background-image: url('/static/development_background.png');
}

.staging-background {
  background-repeat: repeat;
  background-image: url('/static/staging_background.png');
}

.panel-title__icon {
  transition: transform linear 0.15s;
}

a[aria-expanded=true] .panel-title__icon {
  transform: rotate(90deg);
}



