.autosuggest .autosuggest-suggestions ul {
  margin: 0;
  padding: 0;
  list-style-type: none; }

.autosuggest .suggestion {
  display: block;
  padding: 3px 10px; }

.autosuggest .suggestion:hover {
  background-color: #ddd; }
